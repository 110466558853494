/**
 * Gets the top level domain (.com | .it | .es) from a URL
 * @param {string} language
 */
const getTLD = language => {
  const tld = {
    es: ".es",
    it: ".it",
    default: ".com"
  }

  return tld[language] || tld.default
}

/**
 * The cookies are set in the fulcrum server-side rendering
 * @param {object}
 * @param {string}
 */
const setNetreferCookies = ({ params, language, pageType }) => {
  const lobby = pageType.replace(" ", "").toLowerCase()

  const paramsObj = Object.fromEntries(new URLSearchParams(params))

  const { btag } = paramsObj

  // check if US
  const isUS = language.substring(0, 2) === "us"

  const getUSParams = () => {
    const paramsUS = ["a_", "b_", "c_"]
    const valuesArray = btag.split(new RegExp(paramsUS.join("|"), "g")).slice(1)

    const obj = {}

    paramsUS.forEach((value, index) => {
      obj[value] = valuesArray[index]
    })

    const { a_, b_, c_ } = obj

    return `${params}&pid=${a_}&bid=${b_}&c=${c_}`
  }

  const urlParams = isUS ? getUSParams() : params

  const urlFulcrum = `https://www.leovegas${getTLD(language)}/set-affiliate-domain-cookie?${urlParams}&lobby=${lobby}`

  fetch(urlFulcrum, { mode: "no-cors", credentials: "include" })
}

export default setNetreferCookies
