import { useStaticQuery, graphql } from "gatsby"

export const createRedirects = (location, subdivision) => {
  const data = useStaticQuery(graphql`
    query PageData {
      allSitePage {
        nodes {
          context {
            slug
          }
        }
      }
    }
  `)

  let redirectUrl = ""

  const domain = location.origin?.split(".").slice(0, 2).join(".")
  const localhost = "localhost:9000"

  const promoUrl = location.host !== localhost ? `${domain}.com/` : `${domain}/`
  const signUpUrl = `${location.origin}/signup/`

  const params = location.search.substring(1).replace(/\/$/, "")

  const { allSitePage } = data

  const pages = {}

  const addPage = _slug => {
    if (_slug.context?.slug) {
      const slug = _slug.context?.slug.split("/")
      const language = slug[0]
      const page = slug[1]

      if (!pages[page]) {
        pages[page] = []
      }

      pages[page].push({
        language,
        page
      })
    }
  }

  const url = location.pathname.slice(1)

  const pageUrl = url.split("/")

  const pageCountry = pageUrl[0]
  const pageSlug = pageUrl[1]

  // add pages to object
  allSitePage.nodes.map(slug => addPage(slug))

  // filter group of pages with that slug
  const pageGroup = pages[pageSlug]

  const slugSubdiv = [subdivision?.slice(0, 2), subdivision?.slice(2)].join("-")

  const country = subdivision?.slice(0, 2)

  const isSubdivPageAvailable = pageGroup?.some(el => el.language === slugSubdiv)

  // hardcoded check: if REST of CANADA access Ontario page it will redirect to sign-up (because they are within the same country)

  const shouldCaRedirect = pageCountry === "ca-on" && subdivision !== "caon"

  const caSlugIncorrect = country === "ca" && shouldCaRedirect && country !== pageCountry

  // list whitelisted and blacklisted subdivisions

  const subdivisionsWhiteList = []

  const subdivisionsBlackList = ["caon"]

  const isSubdivisionWhitelisted = subdivisionsWhiteList.includes(subdivision)
  const isSubdivisionBlacklisted = subdivisionsBlackList.includes(subdivision)

  const isSubdivisionListed = isSubdivisionWhitelisted || isSubdivisionBlacklisted

  // if blacklisted go to not-available
  if (isSubdivisionBlacklisted) {
    redirectUrl = `/${slugSubdiv}/not-available`
  }

  if (country === "de") {
    redirectUrl = `/de/not-available`
  }

  if (isSubdivisionWhitelisted || caSlugIncorrect) {
    // if promo available go to the page otherwise go to sign-up
    redirectUrl = isSubdivPageAvailable ? `${promoUrl}${slugSubdiv}/${pageSlug}` : signUpUrl
    // add params if available
    redirectUrl = params ? `${redirectUrl}?${params.toString()}` : redirectUrl
  }

  return {
    redirectUrl,
    isSubdivisionListed,
    isSubdivPageAvailable,
    caSlugIncorrect
  }
}
